import 'bootstrap-select'
import 'bootstrap-select/sass/bootstrap-select.scss'
import $ from 'jquery'

$(document).on('turbo:load', onLoad);
$(document).on('turbo:frame-load', onLoad);
$(document).on('turbo:before-cache', onUnload);

let observer;

function onLoad(e) {
  if (!observer) {
    observer = new MutationObserver(onChange);
    observer.observe(document.documentElement || document.body, {
      childList: true,
      subtree: true,
    });
  }
}

function onUnload(e) {
  if (observer) {
    observer.disconnect();
    observer = null;
  }
}

function onChange() {
	$('.wrapper select:not(.inline-editable-field):not(.no-bootstrap-select)').addClass("selectpicker");
	$('.wrapper .selectpicker').each(function (i, el) {
		if (!$(el).parent().hasClass('bootstrap-select')) {
			$(el).selectpicker('refresh');
		}
	});
}
