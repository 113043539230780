$(function () {
  const length = $($('.table-col')[0]).find('.row-content').length
  for (let i = 0; i < length; i++) {
    const allHeights = $(`[data-index=${i}]`)
      .map(function () {
        return $(this).height()
      })
      .get()
    const max = Math.max(...allHeights)
    $(`[data-index=${i}]`).height(max)
  }
})
