import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = [
    "select",
    "columnSelected",
    "formSelected",
  ];

  selectTargets;
  formSelectedTarget;

  connect() {
    this.updateLinks();
  }

  toggleSelect() {
    this.updateLinks();
  }

  // private

  updateLinks() {
    const columns = this.selectTargets.filter((el) => el.checked).map((el) => el.value);
    $("#user_setting_value").val(JSON.stringify(columns))
  }
}
