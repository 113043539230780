import $ from 'jquery'
import 'select2'

$(document).on('turbo:load', onLoad)
$(document).on('turbo:frame-load', (e) => {
  if (e.target.id == 'cage_turbo') onLoad()
})

function onLoad() {
  $('select.strain-select2')
    .select2({
      theme: 'bootstrap',
      tags: true,
      templateResult: templateResult,
      templateSelection: templateSelection
    })
    .on('select2:open', function (e) {
      const select2 = $(e.target).data('select2')
      if (!select2.options.get('multiple')) {
        select2.dropdown.$search.get(0).focus()
      }
    })

  function templateResult(el) {
    const $result = $(`<div class='select2-option'>${el.text}</div>`)
    return $result
  }

  function templateSelection(el) {
    const $result = $(`<div class='select2-option selection'>${el.text}</div>`)
    return $result
  }

  $('.cage_data_strain_name')
    .find('.select2-selection')
    .keydown((ev) => {
      if (ev.which < 32) return
      var target = $('select.strain-select2')
      target.select2('open')
      var search = target.data('select2').dropdown.$search || target.data('select2').selection.$search
      search.focus()
    })
}
