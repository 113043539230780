import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
import $ from 'jquery'

export default class extends Controller {
  static targets = ["field", "errors", "spinner", "success", "failure", "retry"]
  urlValue: string;
  fieldTarget: any;
  errorsTarget: any;
  spinnerTarget: any;
  successTarget: any;
  failureTarget: any;
  retryTarget: any;

  preValue: any;
  skipUntilBlur: boolean;

  connect() {
    if (this.fieldTarget.className.includes("date-remote")) {
      $(this.fieldTarget).datepicker({
        format: 'm/d/yyyy',
        autoclose: true
      }).on("change", this.updateFieldOnChange.bind(this))
    }

    if (this.fieldTarget.type == "date") {
      this.preValue = this.fieldTarget.value
      this.fieldTarget.addEventListener('blur', this.updateFieldOnBlur.bind(this));
      this.fieldTarget.addEventListener('keypress', this.updateFieldOnKeypress.bind(this));
    }
    this.fieldTarget.addEventListener('change', this.updateFieldOnChange.bind(this));
    this.retryTarget.addEventListener('click', this.updateField.bind(this));
    this.resizeTextArea();
  }

  disconnect() {
    this.fieldTarget.removeEventListener('change', this.updateFieldOnChange.bind(this));
    this.fieldTarget.removeEventListener('blur', this.updateFieldOnBlur.bind(this));
    this.fieldTarget.removeEventListener('keypress', this.updateFieldOnKeypress.bind(this));
    this.retryTarget.removeEventListener('click', this.updateField.bind(this));
  }

  async updateFieldOnBlur(event) {
    this.skipUntilBlur = false;
    if (this.preValue != this.fieldTarget.value) {
      this.updateField(event);
    }
  }

  async updateFieldOnChange(event) {
    if (this.skipUntilBlur) return;
    this.updateField(event);
  }

  async updateFieldOnKeypress(event) {
    this.skipUntilBlur = true;
  }

  async updateField(event) {
    try {
      await this.doUpdate(event);
    } catch (error) {
      console.error(error);
      this.handleUnexpectedError();
    }
  }

  async doUpdate(event) {
    this.preValue = this.fieldTarget.value;

    this.retryTarget.classList.add('d-none');
    this.spinnerTarget.classList.remove('d-none');
    this.successTarget.classList.add('d-none');
    this.failureTarget.classList.add('d-none');

    const body = new URLSearchParams();
    body.append("remote-field", "true");
    let value = this.fieldTarget.type == "checkbox"
      ? this.fieldTarget.checked ? "1" : "0"
      : this.fieldTarget.value;
    if (this.fieldTarget.className.includes("date-remote")) {
      value = new Date(value)
    }
    body.append(this.fieldTarget.name, value);
    const dataset = (this.element as any).dataset;
    const request = new FetchRequest(dataset.method, dataset.url, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      body,
      responseKind: 'turbo-stream'
    });
    const response = await request.perform();
    if (response.ok) {
      document.getElementById('alert').classList.add('d-none');
    } else {
      this.handleUnexpectedError();
    }
  }

  handleUnexpectedError() {
    document.getElementById('alert').classList.remove('d-none');
    document.getElementById('alert').innerHTML = 'An error occurred while saving the field. Please check your internet connection';

    this.spinnerTarget.classList.add('d-none');
    this.successTarget.classList.add('d-none');
    this.failureTarget.classList.remove('d-none');

    this.fieldTarget.classList.add('is-invalid');
    this.errorsTarget.classList.remove('d-none');
    this.errorsTarget.classList.add('d-block');
    this.errorsTarget.innerHTML = "Not saved";
    this.retryTarget.classList.remove('d-none');
  }

  resizeTextArea() {
    $(".print-fullwidth").find("textarea").each(function () {
      this.setAttribute("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
    }).on("input", function () {
      $(this).css("height", "0px")
      $(this).css("height", this.scrollHeight + "px")
    });
  }
}
