import $ from 'jquery'

$(document).on('turbo:load', onLoad);
$(document).on('turbo:frame-load', onLoad);

function onLoad(e) {
  $(e.target).find('.clickable-row td:not(.no-nav)')
    .on('click', function () {
      const clickParent = $(this).parent()
      const isLink = $(this).find('a').length > 0
      const isRowLink = $(this).hasClass('row-link')
      const shouldFollowRowLink = isRowLink || !isLink
      if (shouldFollowRowLink) {
        window.location = clickParent.find('td.row-link > a').attr('href')
      }
    })

  $(e.target).find('.clickable-row.row.table-row:not(.no-nav)')
    .on('click', function () {
      const firstChild = $(this).children()[0];
      const index = $(firstChild).attr("data-index");
      const isRowLink = $(this).hasClass('row-link')
      const isLink = $(this).find('a').length > 0
      const shouldFollowRowLink = isRowLink || !isLink
      if (shouldFollowRowLink) {
        window.location = $(`.row-link[data-index=${index}] > a`).attr('href')
      }
    })
}
