import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
const sum = (arr) => arr.reduce((a, b) => a + b, 0);
const parseNumeric = (value) => parseFloat((value || '').replace(/,/g, '') || 0);

export default class extends Controller {
  static targets = [
    "lineItem",
    "markupPercent",
    "adjustment",
    "shipping",
    "tax",
    "subtotal",
    "markup",
    "total"
  ];

  lineItemTargets;
  hasMarkupPercentTarget;
  markupPercentTarget;
  hasAdjustmentTarget;
  adjustmentTarget;
  shippingTarget;
  taxTarget;
  subtotalTarget;
  hasMarkupTarget;
  markupTarget;
  totalTarget;

  update(event) {
    this.updateLineItemTotal(event);
    this.updateFormTotal();
  }

  updateLineItemTotal(event) {
    const el = $(event.target);
    const line_item = el.closest(".order-line-item");
    const quantity = parseInt(line_item.find("input[id$=_quantity]").val() || 0);
    const price_per_item = parseFloat(line_item.find("input[id$=_price_per_item]").val() || 0);
    const markup_percentage = parseFloat(line_item.find("input[id$=_markup_percentage]").val() || 0);
    const adjustment = parseFloat(line_item.find("input[id$=_adjustment]").val() || 0);
    const line_item_total = quantity * price_per_item * (1 + markup_percentage / 100) + adjustment;

    line_item.find(".line-item-total").html(formatter.format(line_item_total));
  }

  updateFormTotal() {
    const subtotal = sum(
      this.lineItemTargets.map((el) => parseNumeric(el.querySelector('.line-item-total').textContent.slice(1)))
    )
    if ($('#sale-line-items').length > 0){
      this.totalTarget.innerHTML = formatter.format(subtotal);
    } else {
      this.subtotalTarget.innerHTML = formatter.format(subtotal);
      const orderSubtotal = parseNumeric(this.subtotalTarget.textContent.slice(1));
      const tax = parseNumeric(this.taxTarget.value);
      const shipping = parseNumeric(this.shippingTarget.value);
      this.totalTarget.innerHTML = formatter.format(sum([orderSubtotal, tax, shipping]));
    }
  }
}
