import React, { useEffect, useRef } from 'react'
import StrainName from './StrainName'
import { Card } from 'react-bootstrap'
import { BoxArrowUpRight } from 'react-bootstrap-icons'

export default function StrainDetails({ strain, includeInternalLink }: { strain: any; includeInternalLink?: boolean }) {
  const internalUrl = `/strains/${strain.id}`
  const nameAndSpecies = `${strain.vendorCode} - ${strain.species}`
  const ref = useRef<HTMLAnchorElement>(null)
  useEffect(() => {
    if (ref.current) {
      ref.current.tabIndex = -1
    }
  }, [])
  if(!strain.vendorCode) {
    return <></>
  }
  return (
    <Card className='strain'>
      <Card.Body>
        <Card.Title>
          <StrainName strain={strain} />
        </Card.Title>
        <Card.Subtitle className='mt-1 mb-0 text-muted'>
          {includeInternalLink ? (
            <Card.Link href={internalUrl}>
              <span style={{ textTransform: 'capitalize' }}>{nameAndSpecies}</span>
            </Card.Link>
          ) : (
            <span style={{ textTransform: 'capitalize' }}>{nameAndSpecies}</span>
          )}
        </Card.Subtitle>
        {strain.synonyms.length > 0 ? <Card.Text>{strain.synonyms.join(', ')}</Card.Text> : <></>}
        {strain.note ? (
          <Card.Text>
            <b>{strain.note}</b>
          </Card.Text>
        ) : (
          <></>
        )}
        {strain.url ? (
          <Card.Link href={strain.url} target='_blank' ref={ref}>
            {strain.url} <BoxArrowUpRight />
          </Card.Link>
        ) : (
          <></>
        )}
      </Card.Body>
    </Card>
  )
}
