import React, { MutableRefObject, ReactNode } from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'

export interface IOptionInput {
  key: string
  name: string
  id: string
  synonyms: string[]
  extraLine?: string
  isNew?: boolean
  isCustom?: boolean
  data?: any
}

export interface IPreppedData {
  input: IOptionInput
  tokens: string[]
  origTokens: string[]
  nameTokens: string[]
  idTokens: string[]
  synonyms: string[][]
}

const prepData = (inputs: IOptionInput[]) => {
  return inputs.map((input: IOptionInput) => {
    const tokens = []
    const origTokens = []

    const nameTokens = input.name.split(tokenRe).filter((token) => token.length > 0)
    tokens.push(...nameTokens.map((token) => token.toLowerCase()))
    origTokens.push(...nameTokens)
    const idTokens = input.id.split(tokenRe).filter((token) => token.length > 0)
    tokens.push('(', ...idTokens.map((token) => token.toLowerCase()), ')')
    origTokens.push('(', ...idTokens, ')')
    const synonyms: string[][] = []
    if (input.synonyms?.length > 0) {
      tokens.push('(')
      origTokens.push('(')
    }
    const strainSynonyms = input.synonyms || []
    strainSynonyms.forEach((token) => {
      const synonymTokens = token.split(tokenRe).filter((_token) => _token.length > 0)
      synonyms.push(synonymTokens)
      tokens.push(...synonymTokens.map((_token) => _token.toLowerCase()), ',')
      origTokens.push(...synonymTokens, ',')
    })
    if (input.synonyms?.length > 0) {
      tokens.push(')')
      origTokens.push(')')
    }

    return { input, tokens, origTokens, nameTokens, idTokens, synonyms }
  })
}

const tokenizeInput = (value: string) => {
  const inputValue = value.trim().toLowerCase()
  const inputLength = inputValue.length
  return inputLength === 0
    ? []
    : inputValue
        .split(tokenRe)
        .map((token) => token.trim())
        .filter((token) => token.length > 0)
}

interface MatchedToken {
  match: boolean
  text: string[]
  isSuper: boolean
}

const tokenToJsx = (token: MatchedToken, index: number) => {
  if (token.text[0].startsWith('<') || token.text[0].startsWith('>')) return <React.Fragment key={index} />
  if (token.text.length === 3) {
    return React.createElement(
      token.isSuper ? 'sup' : 'span',
      { key: String(index) },
      <>
        {token.text[0]}
        <span className={token.match ? 'font-weight-bold text-dark' : ''}>{token.text[1]}</span>
        {token.text[2]}
      </>
    )
  } else {
    return React.createElement(
      token.isSuper ? 'sup' : 'span',
      { key: String(index) },
      <>
        <span className={token.match ? 'font-weight-bold text-dark' : ''}>{token.text[0]}</span>
        {token.text.length > 1 ? token.text[1] : ''}
      </>
    )
  }
}

const matchTokens = (tokens: string[], input: string[]) => {
  let ti = 0
  let ii = 0
  while (ii < input.length && ti < tokens.length) {
    const tok = tokens[ti]
    const tok0 = tok.split(/^(0+)/, 3).filter((_tok) => !!_tok)
    const inp = input[ii]
    if (
      tok.startsWith(inp) ||
      (tok.startsWith('0') && tok0[1].startsWith(inp)) ||
      (/^\s+$/.test(inp) && tokenRe.test(tok))
    ) {
      ii += 1
    }
    ti += 1
  }
  return ii === input.length
}

const getMatchTokens = (tokens: string[], origTokens: string[], input: string[]) => {
  const matches: MatchedToken[] = []
  let ti = 0
  let ii = 0
  let isSuper = false
  while (ii < input.length && ti < tokens.length) {
    const tok = tokens[ti]
    const tok0 = tok.split(/^(0+)/, 3).filter((_tok) => !!_tok)
    const inp = input[ii]
    if (tok.startsWith('<')) {
      isSuper = true
    }
    if (tok.startsWith('>')) {
      isSuper = false
    }
    if (tok.startsWith(inp)) {
      matches.push({
        match: true,
        text: [origTokens[ti].slice(0, inp.length), origTokens[ti].slice(inp.length)],
        isSuper
      })
      ii += 1
    } else if (tok.startsWith('0') && tok0[1].startsWith(inp)) {
      matches.push({
        match: true,
        text: [
          origTokens[ti].slice(0, tok0[0].length),
          origTokens[ti].slice(tok0[0].length, tok0[0].length + inp.length),
          origTokens[ti].slice(tok0[0].length + inp.length)
        ],
        isSuper
      })
      ii += 1
    } else if (/^\s+$/.test(inp) && tokenRe.test(tok)) {
      matches.push({ match: true, text: [origTokens[ti]], isSuper })
      ii += 1
    } else {
      matches.push({ match: false, text: [origTokens[ti]], isSuper })
    }
    ti += 1
  }
  while (ti < tokens.length) {
    const tok = tokens[ti]
    if (tok.startsWith('<')) {
      isSuper = true
    }
    if (tok.startsWith('>')) {
      isSuper = false
    }
    matches.push({ match: false, text: [origTokens[ti]], isSuper })
    ti += 1
  }
  return matches
}

const highlightedSuggestion = (inputTokens: string[], item: IPreppedData, context: 'menu' | 'value') => {
  const matches = getMatchTokens(item.tokens, item.origTokens, inputTokens)

  let start = 0
  let end = item.nameTokens.length
  const name = matches.slice(start, end).map(tokenToJsx)
  start = end + 1
  end = start + item.idTokens.length
  const id = matches.slice(start, end).map(tokenToJsx)
  start = end + 2
  const synonymOut: JSX.Element[][] = []
  item.synonyms.forEach((synonym, index, all) => {
    end = start + synonym.length
    synonymOut.push(matches.slice(start, end).map(tokenToJsx))
    if (index < all.length - 1) {
      synonymOut.push([<>, </>])
    }
    start = end + 1
  })

  return (
    <div key={item.input.id}>
      {item.input.isCustom ? (
        <>
          <span className='text-muted'>New:</span> "<span className=''>{name}</span>"
        </>
      ) : (
        <>
          <span className=''>{name}</span> <span className='text-muted'>({id})</span>
        </>
      )}
      {context === 'menu' && synonymOut.length ? <div className='text-muted'>{synonymOut}</div> : ''}
      {context === 'menu' && item.input.extraLine ? <div className='text-muted'>{item.input.extraLine}</div> : ''}
    </div>
  )
}

function formatOptionLabel(
  option: IPreppedData,
  { context, inputValue, selectValue }: { context: 'menu' | 'value'; inputValue: string; selectValue: any }
) {
  if (option?.input?.id) {
    const inputTokens = tokenizeInput(inputValue) ?? []
    return highlightedSuggestion(inputTokens, option, context)
  } else {
    return (
      <div key='blank'>
        <span className='text-muted'>Select...</span>
      </div>
    )
  }
}

const tokenRe = /(\s*[-/<>.,:;?+()*]\s*|\s+)/g

export interface IOption {
  key?: string
  value?: string
  id?: string
  synonyms?: string[]
  label?: string
  __isNew__?: boolean
}

interface IProps {
  options: IOptionInput[]
  onSelect: (option: IOption | null) => void
  selectedOption: IOption
  placeholder: string
  elRef?: MutableRefObject<HTMLElement>
  isRequired?: boolean
  includeBlank?: boolean
  showValidationErrors?: boolean
  tokenizeOptions?: (options: IOptionInput[]) => IPreppedData[]
  isCreatable?: boolean
  isDisabled?: boolean
  isValid?: boolean
}

export default function TypeaheadSelect({
  options,
  onSelect,
  selectedOption,
  placeholder,
  elRef,
  isRequired = false,
  includeBlank = false,
  showValidationErrors = false,
  tokenizeOptions = prepData,
  isCreatable = false,
  isDisabled = false,
  isValid = true
}: IProps) {
  const preppedData = tokenizeOptions(options)
  const selected = selectedOption ? preppedData.find((prep) => prep.input.id === selectedOption?.id) : null

  function onChange(option: IPreppedData, action: string) {
    if (option) {
      if (option?.input?.id !== selectedOption?.id) {
        onSelect(option.input)
      }
    } else {
      onSelect(null)
    }
  }

  function isOptionSelected(value: IPreppedData) {
    return value?.input?.id === selected?.input?.id
  }

  function filterOption({ label, value, data }, input) {
    if (data.input.isNew) {
      return true
    } else {
      const inputTokens = tokenizeInput(input)
      return inputTokens.length === 0 || matchTokens(data.tokens ?? [], inputTokens)
    }
  }

  const isInvalid = showValidationErrors && (!isValid || (isRequired && !selected))

  const customStyles = (hasError: boolean) => ({
    control: (styles: any) => ({
      ...styles,
      ...(hasError && { borderColor: 'red' })
    })
  })

  function formatCreateLabel(inputValue: string) {
    return (
      <div key='blank'>
        <span className=''>Create "{inputValue}"</span>
      </div>
    )
  }

  function getNewOptionData(inputValue, optionLabel) {
    const data = {
      key: '__new__',
      name: inputValue,
      id: '__new__',
      synonyms: [],
      label: inputValue,
      isNew: true,
      isCustom: true
    }
    const input = { ...data, data }
    return tokenizeOptions([input])[0]
  }

  if (isCreatable) {
    return (
      <>
        <span className={isInvalid ? 'is-invalid' : ''}></span>
        <CreatableSelect
          formatOptionLabel={formatOptionLabel}
          formatCreateLabel={formatCreateLabel}
          getNewOptionData={getNewOptionData}
          isOptionSelected={isOptionSelected}
          filterOption={filterOption}
          options={preppedData}
          onChange={onChange}
          value={selected}
          placeholder={placeholder}
          ref={elRef}
          getOptionValue={(option) => option?.key}
          styles={customStyles(isInvalid)}
          isClearable={includeBlank}
          isDisabled={isDisabled}
        />
      </>
    )
  } else {
    return (
      <>
        <span className={isInvalid ? 'is-invalid' : ''}></span>
        <Select
          formatOptionLabel={formatOptionLabel}
          isOptionSelected={isOptionSelected}
          filterOption={filterOption}
          options={preppedData}
          onChange={onChange}
          value={selected}
          ref={elRef}
          placeholder={placeholder}
          getOptionValue={(option) => option?.key}
          styles={customStyles(isInvalid)}
          isClearable={includeBlank}
          isDisabled={isDisabled}
        />
      </>
    )
  }
}
