import React from 'react'
import { ISuggestion } from './PickStrain'

const tokenRe = /(\s*[-/<>.,:;?+()*]\s*|\s+)/g

const tokenizeInput = (value: string) => {
  const tokens = value
    .trim()
    .split(tokenRe)
    .map((token) => token.trim())
    .filter((token) => token.length > 0)
  const reduced = tokens.reduce(
    (acc, curr) => {
      const isSuper = acc.isSuper ? !curr.startsWith('>') : curr.startsWith('<')
      return { isSuper, tokens: [...acc.tokens, { isSuper, text: curr }] }
    },
    { isSuper: false, tokens: [] }
  )
  return reduced.tokens
}

const tokenToJsx = (token: { isSuper: boolean; text: string }, index: number) => {
  if (token.text.startsWith('<') || token.text.startsWith('>')) {
    return <React.Fragment key={index} />
  } else if (token.isSuper) {
    return <sup key={index}>{token.text}</sup>
  } else {
    return <span key={index}>{token.text}</span>
  }
}

interface IProps {
  strain: ISuggestion
}

export default function StrainName({ strain }: IProps) {
  const tokens = tokenizeInput(strain.name)
  const name = tokens.map(tokenToJsx)

  return <span>{name}</span>
}
