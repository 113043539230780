import $ from 'jquery'

$(document).on('turbo:load', onLoad);
$(document).on('turbo:frame-load', onLoad);

function onLoad(e) {
  $(e.target).find('#reset_button')
    .on('click', function() {
      var form = $(this).parents('form:first');
      form.find("select").val('');
      form.submit();
    })
}
