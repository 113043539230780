import { useEffect, useCallback } from 'react'

function isValidFocusTarget(el: HTMLInputElement | undefined) {
  if (!el) return true
  if (el.type === 'radio' && !el.checked) return false
  if (el.tabIndex === -1) return false
  return true
}

export function useEnterToAdvance() {
  const keyDownHandler = useCallback((event: KeyboardEvent) => {
    const target = event.target as HTMLInputElement
    if (event.key === 'Enter' && target.nodeName === 'INPUT') {
      const form = target.form
      const index = Array.prototype.indexOf.call(form, target)
      let offs = 0
      let el
      do {
        offs += 1
        el = form.elements[index + (event.shiftKey ? -1 : 1) * offs] as HTMLElement | undefined
      } while (!isValidFocusTarget(el))
      if (el) {
        el.focus()
        if (!event.shiftKey) {
          event.preventDefault()
        }
      }
    }
  }, [])
  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler)
    return () => document.removeEventListener('keydown', keyDownHandler)
  }, [])
}
