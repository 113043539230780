$(function () {
  $('#protocol').on('change', function (event) {
    const el = $(event.target)
    const ul = el.closest('ul')
    ul.find('.li-wrap a').each(function () {
      if (el.val()) {
        $(this).attr('href', $(this).attr('href').split('?')[0] + '?protocol_id=' + el.val())
      } else {
        $(this).attr('href', $(this).attr('href').split('?')[0])
      }
    })
    if (!el.val()) {
      $('.protocol_detail').css('display', 'none')
    } else {
      $('.protocol_detail').find('a').attr('href', `/protocols/${el.val()}?protocol_id=${el.val()}`)
      $('.protocol_detail').css('cssText', 'display: block !important')
    }
  })
  $('.open-nav').on('click', function () {
    $('#myNav').css({ width: '100%', opacity: '1' })
  })
  $('#close-nav').on('click', function () {
    $('#myNav').css({ width: '0', opacity: '0' })
  })
})
