import $ from 'jquery'

function updateNotificationCount() {
  $.get("/notifications/unread_count.json", function (data) {
    $("#notifications-link a").attr("alt", "Click to view your " + data + " unread notifications.")
    if (data == 0) {
      $("#notifications-link .badge").hide();
    } else {
      $("#notifications-link .badge").html(data);
      $("#notifications-link .badge").show();
    }
  });
}

//$(document).on('turbo:load', updateNotificationCount);
setInterval(function () {
  if (document.visibilityState === 'visible') {
    updateNotificationCount()
  }
}, 60 * 1000)
