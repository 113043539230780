import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  tabTargets: Element[]
  panelTargets: Element[]

  static targets = [ 'tab', 'panel' ]

  initialize() {
    this.showTab()
  }

  change(event) {
    this.index = this.tabTargets.indexOf(event.currentTarget)
  }

  showTab() {
    this.tabTargets.forEach((tab, index) => {
      const panel = this.panelTargets[index]
      if (panel) {
        tab.classList.toggle("is-active", index === this.index)
        panel.classList.toggle("is-hidden", index !== this.index)
      }
    })
  }

  get index() {
    return this.data.get("index") ? parseInt(this.data.get("index")) : 0
  }

  set index(value) {
    this.data.set("index", String(value))
    this.showTab()
  }
}
