import React, { useState, useRef, useEffect, useReducer, Reducer, useCallback } from 'react'
import QrScanner from 'qr-scanner'
import './room_audit/styles.css'
import { beep } from './room_audit/beep'

const isMobileDevice = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)

function Instructions({ message, latest, cage, count }) {
  return (
    <>
      <h3>{message}</h3>
      <h4>{latest}</h4>
      {cage && (
        <div>
          {cage.retired && <h4>Cage retired</h4>}
          <h5>Cage size: {cage.size}</h5>
          <h5>Number of animals: {cage.number_of_animals}</h5>
        </div>
      )}
      <h5>{count} Scanned</h5>
    </>
  )
}

function QRScanner({ onScan }) {
  const ref = useRef()
  const [scanner, setScanner] = useState<QrScanner>()
  const [scanned, setScanned] = useState(null)

  useEffect(() => {
    if (scanned) {
      onScan(scanned)
    }
  }, [scanned])

  useEffect(() => {
    const s = new QrScanner(ref.current, (result) => setScanned(result), {
      onDecodeError: (e) => setScanned(e),
      highlightScanRegion: true,
      highlightCodeOutline: true,
      returnDetailedScanResult: true
    })
    setScanner(s)
    s.start()

    return function () {
      s.stop()
      s.destroy()
    }
  }, [])
  return <video className='qr-scanner-video' ref={ref}></video>
}

async function reportCageCard(reportURL, cageCardId) {
  const response = await fetch(reportURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ cage_card_id: cageCardId })
  })
  if (!response.ok) {
    return { ok: false }
  } else {
    const data = await response.json()
    return { ok: true, already_exists: data.already_exists }
  }
}

interface ScanTrackerState {
  scanned: Set<number>
  last: number | null
}

function useCageCardScanTracker() {
  const [state, setState] = useReducer<Reducer<ScanTrackerState, Partial<ScanTrackerState>>>(
    (currentState, newState) => ({ ...currentState, ...newState }),
    { scanned: new Set<number>(), last: null }
  )

  function scannedLast(cageCardId) {
    return cageCardId === state.last
  }

  function scannedBefore(cageCardId) {
    return state.scanned.has(cageCardId)
  }

  function noteScanned(cageCardId) {
    if (cageCardId) {
      setState({ scanned: new Set(state.scanned).add(cageCardId), last: cageCardId })
    } else {
      setState({ scanned: state.scanned, last: null })
    }
  }

  return {
    scannedLast,
    scannedBefore,
    noteScanned,
    scanned: state.scanned
  }
}

function cageCardIdFromQRCode(result) {
  const urlString = result.data.startsWith('//')
    ? `https:${result.data}`
    : result.data.startsWith('https://')
    ? result.data
    : `https://${result.data}`
  const url = new URL(urlString)
  const parts = url.pathname.split('/')
  if (parts[0] === '' && parts[1] === 'cage_cards' && parts[3] === 'scan') {
    return parseInt(parts[2])
  }
  if (parts[0] === '' && parts[1] === 'c') {
    return parseInt(parts[2])
  }
}

function ScannedList({ scanned }) {
  const scannedCageCardNumbers = Array.from(scanned.values())
    .map((n) => '#' + n)
    .join(', ')
  return <div>Scanned cage cards: {scannedCageCardNumbers}</div>
}

function changeHighlightColor(color) {
  const highlightSvg = document.querySelector('.scan-region-highlight-svg') as SVGElement
  const codeOutlineSvg = document.querySelector('.code-outline-highlight') as SVGElement

  if (highlightSvg && codeOutlineSvg) {
    highlightSvg.style.stroke = color
    codeOutlineSvg.style.stroke = color
  }
}

export default function RoomAuditScan(props) {
  const { scannedLast, scannedBefore, noteScanned, scanned } = useCageCardScanTracker()

  const [instructions, setInstructions] = useState('Point the camera at a cage card QR code')
  const [latest, setLatest] = useState('')
  const [audioEnabled, setAudioEnabled] = useState(!isMobileDevice)
  const [cage, setCage] = useState(null)
  const [isScanning, setIsScanning] = useState(false)

  function onNewScan(cage) {
    if (audioEnabled) beep('new')
    setLatest(`Scanned Cage Card #${cage.id}`)
    setCage(cage)
  }

  function onExistingScan(cage) {
    if (audioEnabled) beep('existing')
    setLatest(`Already Scanned Cage Card #${cage.id}`)
    setCage(cage)
  }

  function onScanError(cage) {
    if (audioEnabled) beep('error')
    setLatest(`Error Scanning Cage #${cage.id}`)
  }

  async function fetchCage(cageCardId) {
    try {
      const response = await fetch(`/cage_cards/${cageCardId}/cage_info`)
      if (!response.ok) {
        throw new Error('Failed to fetch Cage information')
      }
      const data = await response.json()
      return data
    } catch (error) {
      return null
    }
  }

  async function onScan(result) {
    if (isScanning) {
      return
    }

    if (typeof result === 'string' || result.data === '') {
      noteScanned(null)
      changeHighlightColor('#000000')
      return
    }

    const cageCardId = cageCardIdFromQRCode(result)
    if (cageCardId) {
      setIsScanning(true)

      try {
        const cage = await fetchCage(cageCardId)

        if (scannedLast(cageCardId)) {
          // no-op
        } else if (scannedBefore(cageCardId)) {
          noteScanned(cageCardId)
          onExistingScan(cage)
          changeHighlightColor('#f77600')
        } else {
          noteScanned(cageCardId)
          const response = await reportCageCard(props.report_url, cageCardId)
          if (response.ok && !response.already_exists) {
            onNewScan(cage)
            changeHighlightColor('#00ff00')
          } else if (response.ok) {
            onExistingScan(cage)
            changeHighlightColor('#f77600')
          } else {
            onScanError(cage)
          }
        }
      } finally {
        setIsScanning(false)
      }
    } else if (result.data) {
      setLatest('Not a recognized cage card QR code')
    }
  }

  useEffect(() => {
    if (isMobileDevice) {
      window.addEventListener('click', () => setAudioEnabled(true), { once: true })
      window.addEventListener('touchstart', () => setAudioEnabled(true), { once: true })

      return () => {
        window.removeEventListener('click', () => setAudioEnabled(true))
        window.removeEventListener('touchstart', () => setAudioEnabled(true))
      }
    }
  }, [])

  return (
    <>
      <Instructions message={instructions} latest={latest} cage={cage} count={scanned.size} />
      <div id='scan-container'>
        <QRScanner onScan={onScan} />
      </div>
      <ScannedList scanned={scanned} />
      {isMobileDevice && !audioEnabled && (
        <div id='enable-beep' onClick={() => setAudioEnabled(true)}>
          <p>Tap to enable scanner</p>
        </div>
      )}
    </>
  )
}
