import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {

  connect() {
  }

  change_room(event) {
    const urlParams = new URLSearchParams(window.location.search);
    const protocolId = urlParams.get('protocol_id') || $("#cage_protocol_id").val()
    const frame = document.getElementById('cage_type_turbo') as HTMLFormElement;
    const src = frame.getAttribute('data-src') + "?room_id=" + event.target.value + '&protocol_id=' + protocolId;
    frame.src = src;
  }
}
