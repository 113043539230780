$(document).ready(function () {
  const config_date = {
    dateFormat: 'm/d/Y',
    allowInput: true,
    mode: 'range',
    onChange: function (dateStr, instance) {
      const input = instance.element;
      input.value = dateStr;
    }
  };

  const dateInputs = $('.date-range');
  for (let i = 0; i < dateInputs.length; i++) {
    flatpickr(dateInputs[i], config_date);
  }
});
