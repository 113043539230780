import { resizeTextArea } from './resize-text-area'

function isUsdaSpecies() {
  const species = $('.species-field select').val();
  const usdaSpecies = $('.import-data-usda').data('usda-species').split(",");
  return usdaSpecies.includes(species);
}

function updateImportLineItem($this) {
  const $line_item = $this.closest(".import-line-item");
  $line_item.find(".hidden-column").hide();
  const hasExistingCage = $line_item.find('input.existing-cage-id').val() != '';
  if (hasExistingCage) {
    $line_item.find(".destination-cage-column").show();
  } else {
    $line_item.find(".new-destination-cage-column").show();
  }
  if (isUsdaSpecies()) {
    $line_item.find(".pain-category-field").show();
  }
}

$(function () {
  const descriptionTextArea = ".import_line_items_description"

  $(".import-line-items-table").on("cocoon:after-insert", function (e, line_item) {
    updateImportLineItem(line_item);
    resizeTextArea(descriptionTextArea)
  });
  $(".import-line-items-table .import-line-item").each(function () {
    updateImportLineItem($(this));
    resizeTextArea(descriptionTextArea)
  });

  $('.species-field select').on("change", updateUsdaSpecies);

  function updateUsdaSpecies() {
    const show = isUsdaSpecies();
    $('.pain-category-field').toggle(show);
  }
  if ($('.import-form').length > 0) updateUsdaSpecies();
});
