import $ from 'jquery'
import 'select2'
import { resizeTextArea } from './resize-text-area'

$(document).on('turbo:load', onLoad)

function onLoad() {
  $(".select2-source-option").each(function () {
    const sourceValue = $(this).data('source-value');
    if (sourceValue) {
      $(this).append(new Option(sourceValue, sourceValue, true, true));
      $(this).val(sourceValue).trigger('change');
    }

    $(this).select2({
      tags: true,
      theme: 'bootstrap'
    }).on('select2:open', function (e) {
      const selectBox = $(e.target).data('select2')
      selectBox.dropdown.$search.get(0).focus()
    });
  });
}

$(function () {
  $(".cage-form-protocol-id").on("change", function () {
    const el = $(event.target)
    const url = new URL(window.location);
    if (el.val()) {
      url.searchParams.set("protocol_id", el.val());
    } else {
      url.searchParams.delete("protocol_id");
    }
    window.location = url;
  });

  const notesTextArea = $(".cage_split_cage_data_notes, .cage_split_new_cage_data_notes, .cage_card_params_variables_notes").toArray();
  resizeTextArea(notesTextArea)
})
