$(document).on('turbo:load', onLoad);
$(document).on('turbo:frame-load', onLoad);

function onLoad(e) {
  $(e.target).find('.clear-field-link')
    .on('click', function () {
      const el = $($(this).data('target'))
      el.val('')
      $.rails.fire(el[0], 'change')
      return false
    })
}
