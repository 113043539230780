import React from 'react'
import { IStrainSuggestion } from './AnimalOrderLineItem'
import { ISuggestion } from './PickStrain'
import TypeaheadSelect, { IOptionInput } from './TypeaheadSelect'

interface IProps {
  strains: ISuggestion[]
  onSelect: (suggestion: ISuggestion) => void
  selectedStrain?: ISuggestion
  showValidationErrors?: boolean
}

export default function StrainSelect({ strains, onSelect, selectedStrain, showValidationErrors = false }: IProps) {
  const strainToOption = (strain: IStrainSuggestion): IOptionInput => ({
    key: strain.vendorCode,
    name: strain.name,
    id: strain.vendorCode,
    synonyms: strain.synonyms,
    data: strain,
    isNew: strain.isNew,
    isCustom: strain.isCustom
  })

  const options = strains.map(strainToOption)
  const onSelectOption = (option: IOptionInput | null) => {
    onSelect(option ? ((option as any).data as ISuggestion) : null)
  }
  const selectedOption = selectedStrain ? strainToOption(selectedStrain) : null

  return (
    <TypeaheadSelect
      options={options}
      onSelect={onSelectOption}
      selectedOption={selectedOption}
      placeholder='Type a strain name or #'
      isRequired={true}
      includeBlank={true}
      showValidationErrors={showValidationErrors}
      isCreatable={true}
    />
  )
}
