import $ from 'jquery'

$(document).on('turbo:load', onLoad);
$(document).on('turbo:frame-load', onLoad);

function onLoad(e) {
  $(e.target).find('.protocols.new select#protocol_principal_investigator_id')
    .off('change.protocol-form')
    .on('change.protocol-form', function () {
      const department_id = $(this).find('option:selected').attr('data-department-id')
      if (department_id !== '') {
        $('select#protocol_department_id').val(department_id)
      }
    })
}
