import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  connect() {
    this.sortable = new Sortable(this.element, {
      onEnd: this.end.bind(this),
      onMove: function (evt, _originalEvent) {
        if (evt.related.classList.contains('filtered-col')) return false
      },
      handle: '.table-header',
      filter: '.filtered-col',
      animation: 150
    })
  }

  end() {
    const dataset = Array.from(this.sortable.el.children).map((child) => child.dataset.id)
    const data = new FormData()
    data.append(
      'user_setting',
      JSON.stringify({
        key: this.sortable.el.dataset.key,
        value: dataset.filter((c) => c != null && !['action', 'unread_badges'].includes(c))
      })
    )

    Rails.ajax({
      url: this.sortable.el.dataset.url,
      type: this.sortable.el.dataset.method,
      data: data,
      success: function (res) {
        if (res.url) {
          $('#new_user_setting').attr('action', res.url)
          $('#new_user_setting').append("<input type='hidden' name='_method' value='patch'>")
          $('#index-table').attr('data-method', 'patch')
          $('#index-table').attr('data-url', res.url)
        }
      }
    })
  }
}
