import React, { useState } from 'react'
import StrainDetails from './StrainDetails'
import StrainSelect from './StrainSelect'

export interface ISuggestion {
  id: string
  name: string
  vendorCode: string
  synonyms: string[]
  url: string
  species: string
  isNew?: boolean
  isCustom?: boolean
}

interface Props {
  data: any
}

export default function PickStrain({ data }: Props) {
  const strains = data.map((strain) => ({
    ...strain,
    vendorCode: strain.vendor_code
  }))
  const [selectedStrain, setSelectedStrain] = useState(null)
  const onSelect = (strain: ISuggestion) => setSelectedStrain(strain)

  return (
    <>
      <StrainSelect strains={strains} onSelect={onSelect} />
      {selectedStrain ? <StrainDetails strain={selectedStrain} includeInternalLink={true} /> : ''}
    </>
  )
}
