function camelToSnakeName(name) {
  return name.replaceAll(/([A-Z][^A-Z]?)/g, (match) => '_' + match.toLowerCase())
}

export function camelToSnakeObject(data) {
  if (data && typeof data === 'object') {
    return Object.keys(data).reduce((_data, curr) => {
      _data[camelToSnakeName(curr)] = camelToSnakeObject(data[curr])
      return _data
    }, {})
  } else {
    return data
  }
}
