import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "selector", "internal", "external", ]
  hasSelectorTarget: boolean;
  selectorTarget: any;
  internalTargets: any;
  externalTargets: any;

  static values = {
    type: String
  };
  typeValue: string;

  get selector() {
    return this.hasSelectorTarget ? this.selectorTarget : this.element.querySelector('select');
  }

  get internalElements() {
    return (
      this.internalTargets.length > 0
        ? this.internalTargets
        : Array.from(this.element.querySelectorAll('.funding-source-type-internal'))
    );
  }

  get externalElements() {
    return (
      this.externalTargets.length > 0
        ? this.externalTargets
        : Array.from(this.element.querySelectorAll('.funding-source-type-external'))
    );
  }

  connect() {
    if (this.selector) {
      this.selector.addEventListener('change', this.updateShown.bind(this));
    }
    this.updateShown(null);
  }

  disconnect() {
    if (this.selector) {
      this.selector.removeEventListener('change', this.updateShown.bind(this));
    }
  }

  get internalOptions() {
    return (this.element as any).dataset.internalValues.split(",");
  }

  get choice() {
    return this.selector ? this.selector.value : this.typeValue;
  }

  updateShown(event) {
    const showInternal = this.internalOptions.includes(this.choice);

    this.internalElements.forEach((target: any) => {
      if (showInternal) {
        target.classList.add('show');
      } else {
        target.classList.remove('show');
      }
    });

    this.externalElements.forEach((target: any) => {
      if (!showInternal) {
        target.classList.add('show');
      } else {
        target.classList.remove('show');
      }
    });
  }
}
