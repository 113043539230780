import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "showNewCommentButton", "newComment", "addCommentButton", "commentText", "expand" ]
  showNewCommentButtonTarget: any;
  newCommentTarget: any;
  addCommentButtonTarget: any;
  commentTextTarget: any;
  expandTarget: any;

  connect() {
    this.showNewCommentButtonTarget.addEventListener('click', this.showNewComment.bind(this));
    this.addCommentButtonTarget.addEventListener('click', this.addComment.bind(this));
    this.expandTarget.addEventListener('click', this.expand.bind(this));
  }

  disconnect() {
    this.showNewCommentButtonTarget.removeEventListener('click', this.showNewComment.bind(this));
    this.addCommentButtonTarget.removeEventListener('click', this.addComment.bind(this));
    this.expandTarget.removeEventListener('click', this.expand.bind(this));
  }

  showNewComment(event) {
    this.showNewCommentButtonTarget.classList.add('hide');
    this.newCommentTarget.classList.remove('hide');
    this.commentTextTarget.focus();
  }

  addComment(event) {
    this.showNewCommentButtonTarget.classList.remove('hide');
    this.newCommentTarget.classList.add('hide');
  }

  expand(event) {
    this.element.querySelectorAll('.comment.hide').forEach((el) => el.classList.remove('hide'));
    this.expandTarget.classList.add('hide');
  }
}
