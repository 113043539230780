import $ from 'jquery'

$(document).on('turbo:load', onLoad)
$(document).on('turbo:frame-load', onLoad)

function onLoad(e) {
  let progressItems = $('#progress_bar > li')
  let lastActiveItem = $('#progress_bar > .active').last()
  let penultimateActiveItem = lastActiveItem.prev()
  let firstInactiveItem = lastActiveItem.next()
  ;[penultimateActiveItem, lastActiveItem, firstInactiveItem].forEach((item) => item.addClass('bus-stop'))
  ;[penultimateActiveItem, lastActiveItem, firstInactiveItem].forEach((item) => item.attr('data-toggle', ''))
  ;[penultimateActiveItem, firstInactiveItem].forEach((item) => item.addClass('outer-stop'))
  firstInactiveItem.addClass('last-stop')
  progressItems.not('.bus-stop').text('')
}
