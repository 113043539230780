$(document).on('click', '.set_agenda', function () {
  $(".set_agenda").addClass("hide")
  $(".reset_agenda").removeClass("hide")
  $("#iacuc_committee_meeting_approved_pa").val($("#iacuc_committee_meeting_approved_pa").attr("data"))
  $("#iacuc_committee_meeting_fcr_pa").val($("#iacuc_committee_meeting_fcr_pa").attr("data"))
});
$(document).on('click', '.reset_agenda', function () {
  $(".reset_agenda").addClass("hide")
  $(".set_agenda").removeClass("hide")
  $("#iacuc_committee_meeting_approved_pa").val("")
  $("#iacuc_committee_meeting_fcr_pa").val("")
});
