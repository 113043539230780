import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "selectAll",
    "select",
    "printSelected",
  ];

  selectAllTarget;
  selectTargets;
  printSelectedTarget;

  connect() {
    this.updateLinks();
  }

  toggleSelectAll() {
    this.selectTargets.forEach((el) => (el.checked = this.selectAllTarget.checked));
    this.updateLinks();
  }

  toggleSelect() {
    this.selectAllTarget.checked = this.selectTargets.every((el) => el.checked);
    this.updateLinks();
  }

  // private

  updateLinks() {
    const ids = this.selectTargets.filter((el) => el.checked).map((el) => el.dataset.cageIds.split(",")).flat();
    const url = new URL(this.printSelectedTarget.href);
    url.searchParams.delete('cage_ids[]');
    ids.forEach((id) => url.searchParams.append('cage_ids[]', id));
    this.printSelectedTarget.href = url.toString();
    if (ids.length === 0) {
      this.printSelectedTarget.classList.add('disabled');
    } else {
      this.printSelectedTarget.classList.remove('disabled');
    }
  }
}
