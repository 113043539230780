$(document).on('turbo:load', onLoad);
$(document).on('turbo:frame-load', onLoad);

function onLoad() {
  // Choose the active protocol
  $('#active_protocol_id')
    .off('change.active-protocol-selector')
    .on('change.active-protocol-selector', function () {
      window.location = '/protocols' + ($(this).val() === '' ? '' : '/' + $(this).val())
    })
}
