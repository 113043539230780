import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {
  connect() {
  }

  render_component(event) {
    const dataset = event.target.dataset
    const request = new FetchRequest("get", "/dashboard/render_component?" +
      new URLSearchParams({
        query_params: dataset.queryParams,
        component_id: dataset.componentId,
        component_name: dataset.componentName
      }), { responseKind: "turbo-stream" })
    request.perform()
  }

  render_form(event) {
    event.preventDefault()
    const target = event.target
    const table = target.closest(".inbox")
    const dataset = target.closest(".table-col").dataset
    const order = dataset.sort_order == "asc" ? "desc" : "asc"
    const delivery_date = table.dataset.delivery_date
    const date_for_week = table.dataset.date_for_week

    const request = new FetchRequest("get", "/dashboard/render_form?" +
      new URLSearchParams({
        type: table.id,
        sort_by: dataset.id,
        sort_order: order,
        delivery_date: delivery_date,
        date_for_week: date_for_week,
      }), { responseKind: "turbo-stream" })
    request.perform()
  }
}
