import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

interface Props {
  startDate: string
  endDate: string
  deliveryDates: string[]
  themeSystem: string
}

export default function VendorDeliveryDates({ startDate, endDate, deliveryDates }: Props) {
  const events = deliveryDates.map((date) => ({ start: date, display: 'background' }))
  return (
    <FullCalendar
      plugins={[dayGridPlugin]}
      initialView='dayGridMonth'
      events={events}
      validRange={{ start: startDate, end: endDate }}
      weekends={true}
      navLinks={false}
    />
  )
}
