import $ from 'jquery'
import 'select2'

$(document).on('turbo:load', onLoad)
$(document).on('turbo:frame-load', onLoad)

$(document).on('turbo:before-cache', function () {
  $('.select2').off().select2().select2('destroy')
  $('#person-modal').off().data('bs.modal', null)
  $('#new_person').off()
})

function onLoad() {
  function updateSelectTemplate(data) {
    return $(data.element).text()
  }
  function createTag(params) {
    const term = $.trim(params.term)
    if (term === '') return null
    return { id: '__new__', text: term }
  }
  const $personSelect = $('.ra-select2')
  const $newPersonModal = $('#person-modal')
  const $newPersonForm = $('#person-modal #new_person')
  $personSelect
    .select2({
      theme: 'bootstrap',
      tags: true,
      createTag,
      templateResult: updateSelectTemplate,
      templateSelection: updateSelectTemplate
    })
    .off('select2:select')
    .on('select2:select', function (e) {
      if (e.params.data.id === '__new__') {
        $newPersonModal.modal('show')
        const [first, last] = e.params.data.text.split(' ', 2)
        $('#person_first_name').val(first || '')
        $('#person_last_name').val(last || '')
      }
    })
  $newPersonModal.off('hide.bs.modal').on('hide.bs.modal', function () {
    if ($personSelect.val() === '__new__') {
      $personSelect.find(':selected').remove()
    }
  })
  $newPersonForm
    .attr('data-remote', 'true')
    .attr('data-type', 'json')
    .off('ajax:success')
    .on('ajax:success', function (e) {
      const record = e.detail[0]
      const name = `${record.first_name} ${record.last_name}`
      $('.select2').find(':selected').text(name).val(record.id)
      $('.select2').val(record.id).trigger('change')
      $newPersonModal.modal('hide')
      e.preventDefault()
      return false
    })
    .on('ajax:error', function (e) {
      const message = Object.entries(e.detail[0].errors)
        .map(([field, error]) => `${field}: ${error}`)
        .join('. ')
      $('#person-modal-error').text(message).addClass('alert alert-danger')
    })
}
