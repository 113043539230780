import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';

export default class extends Controller {
  connect() {
    $(this.element).on('change', this.updateForm.bind(this));
  }

  updateForm(_event) {
    const dob = this.parseDate($("#birth_dob").val());
    const species = $("#birth_species").val();
    const expectedWeanDate = $("#birth_expected_wean_date");
    const speciesWeanDate = JSON.parse(sessionStorage.getItem("speciesWeanDate"));

    if (Object.keys(speciesWeanDate).includes(species)) {
      const dayToWean = speciesWeanDate[species];
      const newDate = this.calculateExpectedWeanDate(dob, dayToWean);
      expectedWeanDate.val(newDate.toISOString().split("T")[0]);
    }
  }

  calculateExpectedWeanDate(dob, dayToWean) {
    dob.setDate(dob.getDate() + dayToWean);
    return dob;
  }

  parseDate(input) {
    const parts = input.split("-");
    // Note: months are 0-based in JavaScript Date
    return new Date(parts[0], parts[1] - 1, parts[2]);
  }
}
