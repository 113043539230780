function resizeTextArea(descriptionID) {
  $(descriptionID).find("textarea").each(function () {
    this.setAttribute("style", "height:" + (this.scrollHeight) + "px; overflow-y:hidden;");
  }).on("input", function () {
    $(this).css("height", "0px");
    $(this).css("height", this.scrollHeight + "px");
  });
}

export { resizeTextArea };
