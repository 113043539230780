import * as data from './beepData'

const beeps = {
  new: new Audio(data.beepSuccess),
  existing: new Audio(data.beepExisting),
  error: new Audio(data.beepError)
}

export function beep(name) {
  beeps[name].play()
}
