// Start Bootstrap JS
// -----------------------------------

import $ from 'jquery';

function initBootstrap() {

    // necessary check at least til BS doesn't require jQuery
    if (!$.fn || !$.fn.tooltip || !$.fn.popover) return;

    // POPOVER
    // -----------------------------------

    $('[data-toggle="popover"]').popover({
      container: 'body'
    });
    $(".bio-popover").popover({ trigger: "manual" , html: true, animation:false})
    .on("mouseenter", function () {
        var _this = this;
        $(this).popover("show");
        $(".popover").on("mouseleave", function () {
            $(_this).popover('hide');
        });
    }).on("mouseleave", function () {
        var _this = this;
        setTimeout(function () {
            if (!$(".popover:hover").length) {
                $(_this).popover("hide");
            }
        }, 200);
    });

    // TOOLTIP
    // -----------------------------------

    $('[data-toggle="tooltip"]').tooltip({
        container: 'body'
    });

    // DROPDOWN INPUTS
    // -----------------------------------
    $('.dropdown input').on('click focus', function(event) {
        event.stopPropagation();
    });

}

export default initBootstrap;
