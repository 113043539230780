import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "ifChecked", "ifUnchecked" ]
  hasCheckboxTarget: boolean;
  checkboxTarget: any;
  ifCheckedTargets: any;
  ifUncheckedTargets: any;

  get checkbox() {
    return this.hasCheckboxTarget ? this.checkboxTarget : this.element.querySelector('input[type="checkbox"]') || this.element.querySelector('fieldset span.boolean.true');
  }

  get checkboxIsChecked() {
    return this.checkbox.tagName === 'INPUT' ? this.checkbox.checked : !!this.checkbox;
  }

  get showHideTargets() {
    return (
      this.ifCheckedTargets.length > 0
        ? this.ifCheckedTargets
        : this.element.querySelectorAll(':scope > .show-hide')
    );
  }

  get hideShowTargets() {
    return (
      this.ifUncheckedTargets.length > 0
        ? this.ifUncheckedTargets
        : this.element.querySelectorAll(':scope > .hide-show')
    );
  }

  connect() {
    this.checkbox.addEventListener('change', this.updateShown.bind(this));
    this.updateShown(null);
  }

  disconnect() {
    this.checkbox.removeEventListener('change', this.updateShown.bind(this));
  }

  updateShown(event) {
    this.showHideTargets.forEach((target: any) => {
      if (this.checkboxIsChecked) {
        target.classList.add('show');
      } else {
        target.classList.remove('show');
      }
    });

    this.hideShowTargets.forEach((target: any) => {
      if (this.checkboxIsChecked) {
        target.classList.remove('show');
      } else {
        target.classList.add('show');
      }
    });
  }
}
