import $ from 'jquery'

require('bootstrap-datepicker')
require('bootstrap-datepicker/dist/css/bootstrap-datepicker.css')

$(document).on('turbo:load', onLoad);
$(document).on('turbo:frame-load', onLoad);

function onLoad(e) {
  $(e.target).find('.datepicker input.hidden').each(function() {
    const $hidden = $(this);
    $hidden.hide()

    const [year, month, day] = $hidden.val().split('-').map(Number);
    const date = new Date(Date.UTC(year, month - 1, day));

    if(!isNaN(date)) {
      const $visible = $hidden.closest(".form-group").find("input.visible");
      $visible.val(date.toLocaleDateString("en-US", {year: "numeric", month: "2-digit", day: "2-digit"}));
    }
  });

  $(e.target).find('.datepicker input')
    .datepicker({
      format: 'mm/dd/yyyy',
      autoclose: true
    })
    .on('clearDate', handleDateChange)
    .on('changeDate', handleDateChange)
    .on('focusout', handleDateChange)
}

function handleDateChange(e) {
  var dateString
  const $input = $(e.target);

  if(e.type == "focusout") {
    const [m, d, y] = e.target.value.split("/")
    try {
      dateString = new Date(`${y}-${m}-${d}`).toISOString().split('T')[0]
    } catch(e) {
      if (e instanceof RangeError) {
        dateString = ""
        $input.val("")
      } else {
        throw e;
      }
    }
  } else if(e.type == "clearDate" || e.type == "changeDate") {
    const date = e.date
    dateString = date
      ? new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]
      : ''
  }

  const hiddenField = e.target.previousSibling
  $(hiddenField).val(dateString)
}
