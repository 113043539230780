import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {
  update(event) {
    this.updateCategoryTotalRow(event)
    this.updateYearTotal(event)
    this.updateTotalAll(event)
  }

  updateCategoryTotalRow(event) {
    const el = $(event.target)
    const category_row = el.closest(".category-row")
    const year_1 = parseInt(category_row.find("input[id$='_year[1]']").val()) || 0
    const year_2 = parseInt(category_row.find("input[id$='_year[2]']").val()) || 0
    const year_3 = parseInt(category_row.find("input[id$='_year[3]']").val()) || 0
    category_row.find(".total").html(year_1 + year_2 + year_3)
  }

  updateYearTotal(event) {
    const el = $(event.target)
    const el_last_id = el.attr("id").substr(el.attr("id").length - 8)
    const table = el.closest(".table")
    const category_b = parseInt(table.find(`input[id$='b_by${el_last_id}']`).val()) || 0
    const category_c = parseInt(table.find(`input[id$='c_by${el_last_id}']`).val()) || 0
    const category_d = parseInt(table.find(`input[id$='d_by${el_last_id}']`).val()) || 0
    const category_e = parseInt(table.find(`input[id$='e_by${el_last_id}']`).val()) || 0
    $(`#total_year_${el_last_id[6]}`).html(category_b + category_c + category_d + category_e)
  }

  updateTotalAll(event) {
    const el = $(event.target)
    const table = el.closest(".table")
    const category_b_total = parseInt(table.find("[id$='category_b_by_year_total']").html()) || 0
    const category_c_total = parseInt(table.find("[id$='category_c_by_year_total']").html()) || 0
    const category_d_total = parseInt(table.find("[id$='category_d_by_year_total']").html()) || 0
    const category_e_total = parseInt(table.find("[id$='category_e_by_year_total']").html()) || 0
    $(`#total_year_4`).html(category_b_total + category_c_total + category_d_total + category_e_total)
  }
}
