import $ from 'jquery'

$(document).on('turbo:load', onLoad)
$(document).on('turbo:frame-load', onLoad)

function onLoad(e) {
  $('#mobile-search-icon').on('click', function (e) {
    e.preventDefault
    $('#mobile-search-input').toggleClass('d-block')
  })
}
