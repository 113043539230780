import { useEffect, useCallback } from 'react'

export function usePreventFormSubmitOnEnter() {
  const keyDownHandler = useCallback((event: KeyboardEvent) => {
    const target = event.target as HTMLInputElement
    if (event.key === 'Enter' && target.nodeName === 'INPUT' && target.type !== 'button') {
      event.preventDefault()
    }
  }, [])
  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler)
    return () => document.removeEventListener('keydown', keyDownHandler)
  }, [])
}
