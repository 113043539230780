import $ from 'jquery'

$(document).on('click', '#changelog-icon', function () {
  Rails.ajax({
    type: "POST",
    url: "/changelogs",
    success: function () {
      $('#changelog-icon .badge-danger').hide();
    }
  });
});

$(function () {
  $('#changelog-modal').on('hidden.bs.modal', function () {
    $(this).find('.circle.bg-danger').remove();
  });
})
