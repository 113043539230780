import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {

  }

  change(event) {
    const frame = document.getElementById('wean_turbo') as HTMLFormElement;
    const src = frame.getAttribute('data-src') + "?birth_id=" + event.target.value;
    frame.src = src
  }
}
