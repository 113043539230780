$(document).on('turbo:load', onLoad);
$(document).on('turbo:frame-load', onLoad);

function onLoad(e) {
  const $doc = $(e.target);

  const $addButton = $doc.find('.add_fields');
  $addButton.on('click', function() {
    setTimeout(syncLineItemsList, 0);
  });

  function syncLineItemsList() {
    const $checkboxes = $doc.find('.transfer_line_items_retire_source_cage input[type=checkbox]');
    $checkboxes.on('change', syncOtherCheckboxes);
    const $sourceCages = $doc.find('.transfer_line_items_source_cage_id input');
    $sourceCages.on('change', syncOtherCheckboxes);
  }

  function syncOtherCheckboxes() {
    const sourceCageRetiredState = {};

    $doc.find('.transfer-line-item').each(function() {
      const sourceCage = $(this).find('.transfer_line_items_source_cage_id input').val();
      if (sourceCage.length > 0) {
        const $checkbox = $(this).find('.transfer_line_items_retire_source_cage input[type=checkbox]');
        const sourceCageAlreadyListed = sourceCage in sourceCageRetiredState;
        $checkbox.prop('disabled', sourceCageAlreadyListed);
        if (sourceCageAlreadyListed) {
          const retired = sourceCageRetiredState[sourceCage];
          $checkbox.prop('checked', retired);
        } else {
          sourceCageRetiredState[sourceCage] = $checkbox.is(':checked');
        }
      }
    });
  }
}
