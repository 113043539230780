import axios from 'axios'

// getToken from meta tags
const getToken = () => document.querySelector('meta[name="csrf-token"]').getAttribute('content')
const token = getToken()

export default axios.create({
  baseURL: '/',
  headers: {
    'Content-type': 'application/json',
    'X-CSRF-TOKEN': token
  }
})
