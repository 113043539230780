import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {

  }

  change(event) {
    const selected_option = event.target.options[event.target.selectedIndex];
    const strain_name = selected_option.text;
    const cage_data_strain_name = document.getElementById('cage_data_strain_name') as HTMLInputElement
    const cage_data_source = document.getElementById('cage_data_source') as HTMLInputElement
    const vendor_name = selected_option.getAttribute("data-vendor-name");
    if (cage_data_strain_name) {
      cage_data_strain_name.value = strain_name;
    }
    if (cage_data_source) {
      cage_data_source.value = vendor_name;
    }
  }
}
