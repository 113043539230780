$(document).on('turbo:load', onLoad);
$(document).on('turbo:frame-load', onLoad);

function onLoad(e) {
  $(e.target).find('.color-swatch').each(function () {
    const swatch = $(this)
    const input = $(this).parent().find('select')
    if (input) {
      input.on('change', () => {
        swatch.removeClass()
        swatch.addClass('color-swatch')
        swatch.addClass(input.find('option:selected').text().toLowerCase())
      })
    }
  })
}
