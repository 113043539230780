import $ from 'jquery'

$(document).on('turbo:load', onLoad);
$(document).on('turbo:frame-load', onLoad);

function onLoad() {
  $('.time-ago').each(function () {
    const date = Date.parse($(this).attr('datetime')).toString()
    const dateStr = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(date)
    $(this).attr('title', dateStr)
  })
}
