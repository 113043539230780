import $ from 'jquery'

$(document).on('turbo:load', onLoad)
$(document).on('turbo:frame-load', onLoad)

function onLoad() {
  window.onbeforeprint = function () {
    $('.print-content').remove()
    $('textarea').each(function () {
      var text = $(this).val()
      $(this).after('<p class="print-content">' + text + '</p>')
    })
  }
  window.onafterprint = function () {
    $('.print-content').remove()
  }
}
