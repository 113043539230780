import { resizeTextArea } from './resize-text-area'

let new_cage_id = 1;
function addNewCageTransfer(node, id) {
  const option = $('<option>', { value: id, text: `New Cage ${id}` });
  node.append(option);
}

function setupLineItemTransfer(line_item) {
  const new_cage_list = line_item.find("select.new-cage-list");
  for (let i = 1; i < new_cage_id; i++) {
    addNewCageTransfer(new_cage_list, i);
  }
}

function updateTransferLineItem($this) {
  const $line_item = $this.closest(".transfer-line-item");
  $line_item.find(".hidden-column").hide();
  switch ($this.val()) {
    case "to_existing_cage":
      $line_item.find(".destination-cage-column").show();
      $line_item.find(".retire-cage-column").show();
      $line_item.find(".number-of-animals-column").show();
      $line_item.find(".animal-description-column").show();
      break;
    case "to_new_cage":
      $line_item.find(".new-destination-cage-column").show();
      $line_item.find(".retire-cage-column").show();
      $line_item.find(".number-of-animals-column").show();
      $line_item.find(".animal-description-column").show();
      $line_item.find(".add-cage-column").show();
      break;
  }
}

function showNumberOfAnimals(line_item) {
  const sourceCage = line_item.find(".transfer_line_items_source_cage_id input")
  if (sourceCage.val()) {
    line_item.find(".number-of-animals-column").show()
  }
}

function updateDestinationCage($this) {
  const $line_item = $this.closest(".transfer-line-item");
  $line_item.find(".destination-cage-column input").val(null)
}

$(function () {
  const descriptionTextArea = ".transfer_line_items_animal_description"

  if ($('.transfer-data-usda').length > 0) {
    updatePainCategoryVisibility()
  }
  $(".transfer_source_room").toggle(($("input[name='transfer[source_in_vivarium]'][checked='checked']").val() === "true"));
  $("input[name='transfer[source_in_vivarium]']").on("change", function () {
    const show = $(this).val() === "true";
    $(".transfer_source_room").toggle(show);
  })
  $(".transfer_destination_room").toggle(($("input[name='transfer[destination_in_vivarium]'][checked='checked']").val() === "true"));
  $("input[name='transfer[destination_in_vivarium]']").on("change", function () {
    const show = $(this).val() === "true";
    $(".transfer_destination_room").toggle(show);
  })

  $("#line-items.transfer-line-items-table").on("cocoon:after-insert", function (e, line_item) {
    setupLineItemTransfer(line_item);

    const previousLineItem = $(this).find('.transfer-line-item').not(line_item).last();
    const previousMoveAction = previousLineItem.find("select.transfer-line-item-action").val();

    if (previousMoveAction) {
      line_item.find("select.transfer-line-item-action").val(previousMoveAction).trigger('change');
    }
  });
  $("#line-items .transfer-line-item").each(function () {
    setupLineItemTransfer($(this));
    updateTransferLineItem($(this).find("select.transfer-line-item-action"));
    showNumberOfAnimals($(this));
    resizeTextArea(descriptionTextArea)
  });

  $(document).on("click", ".transfer.generate-cage", function (e) {
    addNewCageTransfer($("select.new-cage-list"), new_cage_id);
    $(this).closest(".transfer-line-item").find("select.new-cage-list").val(new_cage_id);
    new_cage_id += 1;
    e.preventDefault()
  });

  $(document).on("change", "select.transfer-line-item-action", function () {
    updateTransferLineItem($(this));
    updateDestinationCage($(this));
    resizeTextArea(descriptionTextArea);
  });

  // pain category visibility

  function updatePainCategoryVisibility() {
    const usdaSpeciesList = $('.transfer-data-usda').data('usda-species').split(',');
    const species = $("#transfer_species").val();
    const speciesIsUsda = usdaSpeciesList.includes(species);
    const differentProtocols = $("#transfer_source_protocol_id").val() !== $("#transfer_destination_protocol_id").val();
    const show = speciesIsUsda && differentProtocols;
    $(".destination-pain-category").toggle(show);
  }

  $(document).on("change", "#transfer_source_protocol_id, #transfer_destination_protocol_id, #transfer_species", updatePainCategoryVisibility);
  if ($('.transfer-form').length > 0) updatePainCategoryVisibility();
});
