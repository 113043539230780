import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "selectAll",
    "select",
    "editSelected",
    "retireSelected",
    "printSelected",
    "transferSelected",
    "retireSelectedButton",
  ];

  selectAllTarget;
  selectTargets;
  editSelectedTarget;
  hasRetireSelectedTarget;
  retireSelectedTarget;
  printSelectedTarget;
  transferSelectedTarget;
  retireSelectedButtonTarget;

  connect() {
    this.updateLinks();
  }

  toggleSelectAll() {
    this.selectTargets.forEach((el) => (el.checked = this.selectAllTarget.checked));
    this.updateLinks();
  }

  toggleSelect() {
    this.selectAllTarget.checked = this.selectTargets.every((el) => el.checked);
    this.updateLinks();
  }

  // private

  updateLinks() {
    const ids = this.selectTargets.filter((el) => el.checked).map((el) => el.value);
    this.updateLink(this.editSelectedTarget, ids);
    if (this.hasRetireSelectedTarget) {
      this.updateField(this.retireSelectedTarget, ids);
    }
    this.updateLink(this.printSelectedTarget, ids);
    this.updateLink(this.transferSelectedTarget, ids);
  }

  updateLink(link, ids) {
    const url = new URL(link.href);
    url.searchParams.delete('cage_ids[]');
    ids.forEach((id) => url.searchParams.append('cage_ids[]', id));
    link.href = url.toString();
    if (ids.length === 0) {
      link.classList.add('disabled');
    } else {
      link.classList.remove('disabled');
    }
  }

  updateField(field, ids) {
    field.value = ids.join(',');
    if (ids.length === 0) {
      this.retireSelectedButtonTarget.classList.add('disabled');
    } else {
      this.retireSelectedButtonTarget.classList.remove('disabled');
    }
  }
}
