import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {

  }

  change(event) {
    const protocolId = event.target.dataset.protocol_id
    const frame = document.getElementById('cage_turbo') as HTMLFormElement;
    const src = frame.getAttribute('data-src') + "?cage_card_template_class=" + event.target.value + '&protocol_id=' + protocolId;
    frame.src = src;
  }
}
