document.addEventListener('turbo:load', () => {
  window.markerConfig = {
    destination: markerio_destination_id,
    source: 'snippet'
  }

  // begin Marker.io snippet
  !(function (e, r, a) {
    if (!e.__Marker) {
      e.__Marker = {}
      var t = [],
        n = { __cs: t }
      ;[
        'show',
        'hide',
        'isVisible',
        'capture',
        'cancelCapture',
        'unload',
        'reload',
        'isExtensionInstalled',
        'setReporter',
        'setCustomData',
        'on',
        'off'
      ].forEach(function (e) {
        n[e] = function () {
          var r = Array.prototype.slice.call(arguments)
          r.unshift(e), t.push(r)
        }
      }),
        (e.Marker = n)
      var s = r.createElement('script')
      ;(s.async = 1), (s.src = 'https://edge.marker.io/latest/shim.js')
      var i = r.getElementsByTagName('script')[0]
      i.parentNode.insertBefore(s, i)
    }
  })(window, document)
  // end Marker.io snippet
})

document.addEventListener('turbo:before-render', () => {
  delete window.Marker
  delete window.__Marker
})

window.reportIssue = () => {
  const currentUser = document.getElementById('current-user')
  if (currentUser) {
    Marker.setReporter({
      email: currentUser.getAttribute('data-email'),
      fullName: currentUser.getAttribute('data-name')
    })
  }
  Marker.capture('fullscreen')
}
